import { ServicesCatalogServer } from '@wix/ambassador-services-catalog-server/http';
import type {
  NavigateToPackagePickerOptions,
  NavigateToCheckoutOptions,
} from '@wix/pricing-plans-tpa-api';
import {
  CalendarServer,
  ListSlotsResponse,
} from '@wix/ambassador-calendar-server/http';
import { getSession } from '@wix/ambassador-bookings-calendar-v1-session/http';
import { list } from '@wix/ambassador-pricing-plans-v2-benefit/http';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { ScheduleAvailability } from '@wix/ambassador-availability-calendar/types';
import { BookingsServer } from '@wix/ambassador-bookings-server/http';
import { PreviewPriceResponse } from '@wix/ambassador-bookings-v2-price-info/types';
import {
  AddToCartResponse,
  CreateCheckoutFromCurrentCartRequest,
  UpdateCartRequest,
  UpdateCartResponse,
} from '@wix/ambassador-ecom-v1-cart/types';
import {
  updateCurrentCart,
  createCheckoutFromCurrentCart as ecomCreateCheckoutFromCurrentCart,
} from '@wix/ambassador-ecom-v1-cart/http';
import { TotalsCalculator } from '@wix/ambassador-totals-calculator/http';
import { CalculateTotalsResponse } from '@wix/ambassador-totals-calculator/types';
import { CouponsServer } from '@wix/ambassador-coupons-server/http';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { CatalogData, OnError } from './types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import { PlanWithDescription } from '../types/types';
import {
  Checkout as EcomCheckoutServer,
  CreateCheckoutResponse,
  CreateOrderRequest,
  CreateOrderResponse,
  UpdateCheckoutRequest,
} from '@wix/ambassador-checkout/http';
import {
  ControllerFlowAPI,
  IHttpClient,
  ReportError,
} from '@wix/yoshi-flow-editor';
import type { Experiments } from '@wix/yoshi-flow-editor';
import {
  ListEligibleMembershipsResponse,
  MembershipsSpiHost,
} from '@wix/ambassador-memberships-spi-host/http';
import { AvailabilityCalendar } from '@wix/ambassador-availability-calendar/http';
import {
  createConfig,
  CheckoutApiConfig,
  getCurrentCart,
  getBookingsLineItemsOptions,
  BookingsLineItemOption,
} from '@wix/bookings-checkout-api';
import { FormSelectedSlot } from '../types/formSelectedSlots';
import { ContactDetails } from '../types/ambassador/bookings/ambassador-bookings-v2-booking';
import {
  AddToCartArgs,
  addToCart,
} from './implementations/addToCart/addToCart';
import { queryBookings } from './implementations/queryBookings';
import {
  MapContactDetailsArgs,
  mapContactDetails,
} from './implementations/mapContactDetails/mapContactDetails';
import {
  GeneralBookingCheckoutFlowArgs,
  generalBookingCheckoutFlow,
} from './implementations/generalBookingCheckoutFlow/generalBookingCheckoutFlow';
import { FormApiContext, QueryBookingsArgs } from './implementations/utils';
import { getPublicPlans } from './implementations/getPublicPlans/getPublicPlans';
import {
  GetAppointmentAvailabilityArgs,
  getAppointmentAvailability,
} from './implementations/getAppointmentAvailability/getAppointmentAvailability';
import { getCatalogData } from './implementations/getCatalogData/getCatalogData';
import {
  ListMembershipsArgs,
  listMemberships,
} from './implementations/listMemberships/listMemberships';
import { GetSlotsArgs, getSlots } from './implementations/getSlots/getSlots';
import {
  GetMemberDetailsArgs,
  getMemberDetails,
} from './implementations/getMemberDetails/getMemberDetails';
import {
  GetOptionsAndVariantsDataArgs,
  getOptionsAndVariantsData,
} from './implementations/getOptionsAndVariantsData/getOptionsAndVariantsData';
import {
  GetScheduleAvailabilityArgs,
  getScheduleAvailability,
} from './implementations/getScheduleAvailability/getScheduleAvailability';
import {
  CheckoutBookingArgs,
  checkoutBooking,
} from './implementations/checkoutBooking/checkoutBooking';
import {
  GetAvailabilityArgs,
  getAvailability,
} from './implementations/getAvailability/getAvailability';
import {
  AreCouponsAvailableForServiceArgs,
  areCouponsAvailableForService,
} from './implementations/areCouponsAvailableForService/areCouponsAvailableForService';
import {
  CalculateTotalPriceArgs,
  calculateTotalPrice,
} from './implementations/calculateTotalPrice/calculateTotalPrice';
import {
  PreviewPriceArgs,
  previewPrice,
} from './implementations/previewPrice/previewPrice';
import { ServerErrorType } from '../types/errors';
import { Form } from '../types/ambassador/bookings/ambassador-services-catalog';
import { getForm, getFormArgs } from './implementations/getForm/getForm';
import { isMembersAreaInstalled } from './implementations/isMembersAreaInstalled';
import { IFlowApiAdapter } from '../utils/flow-api-adapter/types';
import { isUpsellPluginInstalled } from './implementations/isUpsellPluginInstalled/isUpsellPluginInstalled';
import {
  getPolicyStringToUse,
  GetPolicyStringToUseArgs,
} from './implementations/getPolicyStringToUse/getPolicyStringToUse';
import { fetchLocaleData as fetchLocaleDataApi } from './implementations/fetchLocaleData/fetchLocaleData';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const BOOKINGS_SERVER_URL = '_api/bookings';
export const ECOM_CHECKOUT_URL = 'ecom';
export const CALENDAR_SERVER_URL = '_api/calendar-server';
export const AVAILABILITY_CALENDAR_SERVER_URL = '_api/availability-calendar';
export const MEMBERS_SERVER_API = '_api/members/v1/members';
export const COUPONS_SERVER_URL = '_api/coupons-server';
export const TOTALS_CALCULATOR = '_api/totals-calculator';
export const MEMBERSHIPS_SPI = '_api/memberships-spi-host';
export const PAYMENT_SERVICES_URL = '_api/payment-services-web';

const BOOKINGS_FES_BASE_DOMAIN = '/_api/bookings-viewer/visitor';
const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
const REVISION_HEADER_NAME = 'x-wix-site-revision';

export class FormApi {
  private experiments: Experiments;
  private flowApi: ControllerFlowAPI;
  private wixSdkAdapter: IFlowApiAdapter;
  private reportError: ReportError;
  private httpClient: IHttpClient;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private bookingsServer: ReturnType<typeof BookingsServer>;
  private ecomCheckoutServer: ReturnType<typeof EcomCheckoutServer>;
  private calendarServer: ReturnType<typeof CalendarServer>;
  private availabilityCalendarServer: ReturnType<typeof AvailabilityCalendar>;
  private couponsServer: ReturnType<typeof CouponsServer>;
  private membersServer: ReturnType<typeof MembersNgApi>;
  private totalsCalculatorServer: ReturnType<typeof TotalsCalculator>;
  private membershipsServer: ReturnType<typeof MembershipsSpiHost>;

  constructor({
    httpClient,
    flowApi,
    wixSdkAdapter,
    reportError,
    experiments,
  }: {
    httpClient: IHttpClient;
    bookingsDataCapsule: BookingsDataCapsule;
    flowApi: ControllerFlowAPI;
    wixSdkAdapter: IFlowApiAdapter;
    reportError: ReportError;
    experiments: Experiments;
  }) {
    this.flowApi = flowApi;
    this.experiments = experiments;
    this.httpClient = httpClient;
    this.wixSdkAdapter = wixSdkAdapter;
    this.reportError = reportError;
    const baseUrl = wixSdkAdapter.getServerBaseUrl();
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.bookingsServer = BookingsServer(`${baseUrl}${BOOKINGS_SERVER_URL}`);
    this.ecomCheckoutServer = EcomCheckoutServer(
      `${baseUrl}${ECOM_CHECKOUT_URL}`,
    );
    this.calendarServer = CalendarServer(`${baseUrl}${CALENDAR_SERVER_URL}`);
    this.couponsServer = CouponsServer(`${baseUrl}${COUPONS_SERVER_URL}`);
    this.membersServer = MembersNgApi(`${baseUrl}${MEMBERS_SERVER_API}`, {
      ignoredProtoHttpUrlPart: '/v1/members',
    });
    this.availabilityCalendarServer = AvailabilityCalendar(
      `${baseUrl}${AVAILABILITY_CALENDAR_SERVER_URL}`,
    );
    this.totalsCalculatorServer = TotalsCalculator(
      `${baseUrl}${TOTALS_CALCULATOR}`,
    );
    this.membershipsServer = MembershipsSpiHost(`${baseUrl}${MEMBERSHIPS_SPI}`);
  }

  private getFormApiContext(): FormApiContext {
    return {
      httpClient: this.httpClient,
      flowApi: this.flowApi,
      queryBookings: this.queryBookings,
      authorization: this.getAuthorization(),
      ecomCheckoutServer: this.ecomCheckoutServer,
      calendarServer: this.calendarServer,
      availabilityCalendarServer: this.availabilityCalendarServer,
      couponsServer: this.couponsServer,
      membersServer: this.membersServer,
      totalsCalculatorServer: this.totalsCalculatorServer,
      membershipsServer: this.membershipsServer,
      bookingsServer: this.bookingsServer,
      catalogServer: this.catalogServer,
      experiments: this.experiments,
      reportError: this.reportError,
      wixSdkAdapter: this.wixSdkAdapter,
    };
  }

  async notifyOwnerNonPremiumEnrollmentAttempt() {
    return this.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/classes/nonPremium`,
      '',
      {
        headers: {
          [REVISION_HEADER_NAME]: this.wixSdkAdapter.getSiteRevision(),
          [XSRF_HEADER_NAME]: this.wixSdkAdapter.getCsrfToken(),
        },
      },
    );
  }

  async notifyOwnerNonPricingPlanEnrollmentAttempt(data: object) {
    return this.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/pricing-plans/invalidSetup`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          [REVISION_HEADER_NAME]: this.wixSdkAdapter.getSiteRevision(),
          [XSRF_HEADER_NAME]: this.wixSdkAdapter.getCsrfToken(),
        },
      },
    );
  }

  getPolicyStringToUse(args: GetPolicyStringToUseArgs) {
    return getPolicyStringToUse({
      ...args,
      ...this.getFormApiContext(),
    });
  }
  getAuthorization() {
    return this.wixSdkAdapter.getInstance();
  }

  getConfig(): CheckoutApiConfig {
    return createConfig({
      experiments: this.experiments,
      getAuthorization: () => this.getAuthorization(),
      getBaseUrl: () => this.wixSdkAdapter.getServerBaseUrl(),
      httpClient: this.httpClient,
    });
  }

  async getSessionById({ sessionId }: { sessionId: string }) {
    const sessionResponse = await this.httpClient.request(
      getSession({ id: sessionId }),
    );

    return sessionResponse.data.session;
  }

  async navigateToPricePlanPackagePicker(
    options: NavigateToPackagePickerOptions,
  ) {
    const pricingPlansApi = await this.wixSdkAdapter.getPricingPlansApi();
    return pricingPlansApi.navigateToPackagePicker(options);
  }

  async navigateToPricePlanCheckout(options: NavigateToCheckoutOptions) {
    const pricingPlansApi = await this.wixSdkAdapter.getPricingPlansApi();
    return pricingPlansApi.navigateToCheckout(options);
  }

  async getPublicPlans(): Promise<PlanWithDescription[] | undefined> {
    return getPublicPlans(this.getFormApiContext());
  }

  async getPricingPlansBenefitList() {
    let listResponse;

    try {
      listResponse = await this.httpClient.request(list({}));
    } catch (e) {
      return [];
    }

    return listResponse?.data?.benefitsWithPlanInfo || [];
  }

  async getAppointmentAvailability(args: GetAppointmentAvailabilityArgs) {
    return getAppointmentAvailability({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async isMembersAreaInstalled() {
    return isMembersAreaInstalled({
      ...this.getFormApiContext(),
    });
  }

  async isUpsellPluginInstalled() {
    return isUpsellPluginInstalled({
      ...this.getFormApiContext(),
    });
  }

  async getCatalogData(
    args: {
      formSelectedSlot?: FormSelectedSlot;
      onError?: OnError;
    } = {},
  ): Promise<CatalogData> {
    return getCatalogData({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async getSlots(args: GetSlotsArgs): Promise<ListSlotsResponse> {
    return getSlots({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async getMemberDetails(args: GetMemberDetailsArgs): Promise<Maybe<Member>> {
    return getMemberDetails({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async getLineItemOptionsFromCart(): Promise<BookingsLineItemOption[]> {
    return getBookingsLineItemsOptions(this.getConfig());
  }

  async getOptionsAndVariantsData(
    args: GetOptionsAndVariantsDataArgs,
  ): Promise<{ [key: string]: ServiceOptionsAndVariants | undefined }> {
    return getOptionsAndVariantsData({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async getForm(args: getFormArgs): Promise<Form | undefined> {
    return getForm({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async getAvailability(args: GetAvailabilityArgs) {
    return getAvailability({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async getScheduleAvailability(
    args: GetScheduleAvailabilityArgs,
  ): Promise<ScheduleAvailability> {
    return getScheduleAvailability({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async listMemberships(
    args: ListMembershipsArgs,
  ): Promise<{ [key: string]: ListEligibleMembershipsResponse }> {
    return listMemberships({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async areCouponsAvailableForService(
    { onError }: AreCouponsAvailableForServiceArgs = {
      onError: (error: ServerErrorType) => {},
    },
  ): Promise<boolean> {
    return areCouponsAvailableForService({
      onError,
      ...this.getFormApiContext(),
    });
  }

  async getCartLineItems() {
    const cartLineItems = await getCurrentCart(this.getConfig());
    return cartLineItems;
  }

  async addToCart(args: AddToCartArgs) {
    return addToCart({
      ...this.getFormApiContext(),
      ...args,
    });
  }

  async queryBookings(args: QueryBookingsArgs) {
    return queryBookings({
      ...this.getFormApiContext(),
      ...args,
    });
  }

  async generalBookingCheckoutFlow(args: GeneralBookingCheckoutFlowArgs) {
    return generalBookingCheckoutFlow({
      ...this.getFormApiContext(),
      ...args,
    });
  }

  async checkoutBooking(args: CheckoutBookingArgs): Promise<{
    bookingIds?: { [key: string]: string };
    createCheckoutResponse?: CreateCheckoutResponse | CreateOrderResponse;
    addToCurrentCartResponse?: AddToCartResponse;
  }> {
    return checkoutBooking({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async createCheckoutFromCurrentCart(
    createCheckoutFromCurrentCartRequest: CreateCheckoutFromCurrentCartRequest,
  ) {
    return (
      await this.httpClient.request(
        ecomCreateCheckoutFromCurrentCart(createCheckoutFromCurrentCartRequest),
      )
    ).data;
  }

  async createOrderFromCheckout(createOrderRequest: CreateOrderRequest = {}) {
    return this.ecomCheckoutServer
      .CheckoutService()({
        Authorization: this.getAuthorization(),
      })
      .createOrder(createOrderRequest);
  }

  async updateCart({
    cartInfo,
  }: UpdateCartRequest): Promise<UpdateCartResponse> {
    return (
      await this.httpClient.request(
        updateCurrentCart({
          cartInfo,
        }),
      )
    ).data;
  }

  async updateCheckout(updateCheckoutRequest: UpdateCheckoutRequest) {
    return this.ecomCheckoutServer
      .CheckoutService()({
        Authorization: this.getAuthorization(),
      })
      .updateCheckout(updateCheckoutRequest);
  }

  async calculateTotalPrice(
    args: CalculateTotalPriceArgs,
  ): Promise<CalculateTotalsResponse> {
    return calculateTotalPrice({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  async previewPrice(args: PreviewPriceArgs): Promise<PreviewPriceResponse> {
    return previewPrice({
      ...args,
      ...this.getFormApiContext(),
    });
  }

  public mapContactDetails(args: MapContactDetailsArgs): ContactDetails {
    return mapContactDetails({
      ...this.getFormApiContext(),
      ...args,
    });
  }

  async fetchLocaleData(): Promise<{ [key: string]: string }> {
    return fetchLocaleDataApi({
      ...this.getFormApiContext(),
    });
  }
}
