import { ServiceData, SlotService } from '../../../../../utils/state/types';
import { Submission } from '@wix/forms-ui/types';
import { mapFormSubmission } from '../../../../../utils/mappers/form-submission.mapper';
import { CartFlow, CartModalStatus } from '../../../../../types/types';
import {
  BookingsQueryParams,
  IFlowApiAdapter,
} from '../../../../../utils/flow-api-adapter/types';
import { CreateActionParams } from '../../actions';
import {
  bookingsUouBookFlowBookingFormClickNext,
  bookingsUouBookFlowBookingFormClickNextFailure,
  bookingsUouSessionAddedToCartModalInteractions,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { BookErrorType } from '../../../../../types/errors';
import {
  CreateCheckoutResponse,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/http';
import { FormStatus } from '../../../../../types/form-state';
import { ExperimentsConsts } from '../../../../../consts/experiments';
import { mapCartToBookingsLineItems } from '../../../../../utils/mappers/cart.mapper';
import {
  AddToCartResponse,
  Cart,
  UpdateCartRequest,
} from '@wix/ambassador-ecom-v1-cart/types';
import { FormApi } from '../../../../../api/FormApi';
import { getContactDetailsWithFieldIds } from '../../../../../utils/mappers/collapseForm.mapper';
import {
  CartModalInteractionActionName,
  FormClickNextAction,
} from '../../../../../types/biLoggerTypes';
import { Experiments } from '@wix/yoshi-flow-editor';
import { canCompleteBoooking } from '../../../../../utils/validations/canBook';
import { ContactDetails } from '../../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import {
  FormBiLogger,
  extraInfoMapper,
  getDynamicPriceBIReportParams,
} from '../../../../../utils/bi/biLoggerFactory';
import { getServiceSlotIdentifier, mapToArray } from '../../../../../utils';

export async function handleSingleSessionFlow(
  {
    getControllerState,
    internalActions: { errorHandlers },
    context: { biLogger, wixSdkAdapter, formApi, reportError, experiments },
  }: CreateActionParams,
  submission: Submission,
  cartFlow?: CartFlow,
) {
  const [state, setState] = getControllerState();
  const {
    businessInfo,
    couponInfo,
    isCart,
    isServiceInCart,
    collapseFormValues,
    isDayful,
    serviceData,
    isUpsellPluginInstalled,
    memberDetails,
  } = state;

  if (isServiceInCart) {
    submission = {
      ...submission,
      ...getContactDetailsWithFieldIds({
        form: getFormSchema(serviceData),
        contactDetails: collapseFormValues,
      }),
    };
  }

  if (
    !(await canCompleteBoooking({
      formApi,
      isDayful: isDayful!,
      wixSdkAdapter,
      serviceData,
      activeFeatures: businessInfo.activeFeatures!,
    }))
  ) {
    setState({ status: FormStatus.IDLE });
    return;
  }

  try {
    const {
      contactDetails,
      additionalFields,
      numberOfParticipants,
      sendSmsReminder,
    } = mapFormSubmission({
      submission,
      formSchema: serviceData.formSchema,
      businessInfo,
      collapseFormValues,
      timezone: getTimezone(serviceData),
    });

    if (memberDetails?.contactId) {
      contactDetails.contactId = memberDetails?.contactId;
    }

    const { createCheckoutResponse, bookingIds, addToCurrentCartResponse } =
      await formApi.checkoutBooking({
        serviceData,
        contactDetails,
        additionalFields,
        sendSmsReminder,
        appliedCoupon: couponInfo.appliedCoupon,
        numberOfParticipants,
        isCart,
        cartFlow,
        onError: (error, details) => {
          errorHandlers.addError(error, {
            numberOfParticipants,
            message: details,
          });
          biLogger?.report(
            bookingsUouBookFlowBookingFormClickNextFailure({
              errorReason: error,
            }),
          );
        },
        country: businessInfo.countryCode!,
        couponCheckboxChecked: couponInfo.isCouponCheckboxChecked,
      });

    if (isCart && addToCurrentCartResponse && !addToCurrentCartResponse?.cart) {
      setState({ status: FormStatus.IDLE });
      return;
    } else {
      if (
        createCheckoutResponse &&
        bookingIds &&
        !isSubmitValid(createCheckoutResponse, bookingIds)
      ) {
        setState({ status: FormStatus.IDLE });
        return;
      }

      await reportBiForBookingsUouBookFlowBookingFormClickNext({
        serviceData,
        sendSmsReminder,
        bookingIds,
        biLogger,
        isCart,
        numberOfParticipants,
        wixSdkAdapter,
      });
    }

    let continueShoppingUrl = '';

    const queryParams = wixSdkAdapter.getUrlQueryParams();
    if (isDayful) {
      continueShoppingUrl =
        queryParams.origin ||
        `${await wixSdkAdapter.getBookOnlineFullUrl()}?dayful=${
          queryParams.dayful
        }`;
    }

    if (addToCurrentCartResponse?.cart) {
      const lineItems = addToCurrentCartResponse?.cart?.lineItems || [];
      if (lineItems.length === 1) {
        await updateCartWithMemberDetails({
          addToCurrentCartResponse,
          contactDetails,
          formApi,
          country: businessInfo.countryCode!,
          experiments,
        });
      }
      if (
        wixSdkAdapter.getUrlQueryParamValue(
          BookingsQueryParams.CONTINUE_SHOPPING_URL,
        ) ||
        isUpsellPluginInstalled
      ) {
        setState({
          cartModal: {
            status: CartModalStatus.OPEN,
            bookingIds: Object.values(bookingIds || {}),
            lineItems,
            biLoggerData: getBiLoggerData({
              serviceData,
              sendSmsReminder,
              bookingIds,
              wixSdkAdapter,
            }),
          },
          status: FormStatus.IDLE,
        });
        await wixSdkAdapter.reloadCart();
        biLogger?.report(
          bookingsUouSessionAddedToCartModalInteractions({
            ...getBiLoggerData({
              serviceData,
              sendSmsReminder,
              bookingIds,
              wixSdkAdapter,
            }),
            actionName: CartModalInteractionActionName.Load,
          }),
        );
      } else {
        await wixSdkAdapter.navigateAfterAddToCart(true);
      }
    } else {
      if (createCheckoutResponse && isOnlineFlow(createCheckoutResponse)) {
        return wixSdkAdapter.navigateToEcomCheckoutPage({
          checkoutId: createCheckoutResponse!.checkout!.id!,
          ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
        });
      } else {
        return wixSdkAdapter.navigateToEcomThankYouPage({
          orderId: createCheckoutResponse!.orderId!,
          ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
        });
      }
    }
  } catch (error) {
    errorHandlers.addError(error as BookErrorType);
    reportError(error as BookErrorType);
    setState({ status: FormStatus.IDLE });
    biLogger?.report(
      bookingsUouBookFlowBookingFormClickNextFailure({
        errorReason: error as Maybe<string>,
      }),
    );
  }
}

const isSubmitValid = (
  createCheckoutResponse: CreateCheckoutResponse | CreateOrderResponse,
  bookingIds: { [key: string]: string },
) => {
  if (!Object.keys(bookingIds).length) {
    return false;
  }

  if (isOnlineFlow(createCheckoutResponse)) {
    return createCheckoutResponse.checkout?.id !== undefined;
  }

  return createCheckoutResponse?.orderId !== undefined;
};

const getBiLoggerData = ({
  serviceData,
  sendSmsReminder,
  bookingIds,
  numberOfParticipants,
  wixSdkAdapter,
}: {
  serviceData: ServiceData;
  sendSmsReminder: boolean;
  bookingIds: { [key: string]: string } | undefined;
  numberOfParticipants?: number;
  wixSdkAdapter?: IFlowApiAdapter;
}) => {
  const firstService = mapToArray<SlotService>(serviceData.slotServices)[0];
  let dynamicPriceBIReportParams;
  if (firstService.dynamicPriceInfo?.serviceOptionsAndVariants) {
    dynamicPriceBIReportParams = getDynamicPriceBIReportParams(
      firstService.dynamicPriceInfo!,
      true,
    );
  }

  const bookingId =
    bookingIds?.[getServiceSlotIdentifier(firstService.nestedSlot)];

  return {
    smsNotificationRequest: sendSmsReminder,
    extraInfo: extraInfoMapper({
      serviceData,
      numberOfParticipants: numberOfParticipants || 1,
      bookingIds,
      withDynamicPrice: true,
      wixSdkAdapter: wixSdkAdapter!,
    }),
    ...(bookingId ? { bookingId } : {}),
    ...(dynamicPriceBIReportParams ? dynamicPriceBIReportParams : {}),
    numberOfServices: mapToArray<SlotService>(serviceData.slotServices).length,
  };
};

async function reportBiForBookingsUouBookFlowBookingFormClickNext({
  serviceData,
  sendSmsReminder,
  bookingIds,
  biLogger,
  isCart,
  numberOfParticipants,
  wixSdkAdapter,
}: {
  serviceData: ServiceData;
  sendSmsReminder: boolean;
  bookingIds: { [key: string]: string } | undefined;
  biLogger: FormBiLogger | undefined;
  isCart: boolean | undefined;
  numberOfParticipants?: number;
  wixSdkAdapter?: IFlowApiAdapter;
}) {
  await biLogger?.report(
    bookingsUouBookFlowBookingFormClickNext({
      ...getBiLoggerData({
        serviceData,
        sendSmsReminder,
        bookingIds,
        numberOfParticipants,
        wixSdkAdapter,
      }),
      ...(isCart ? { action: FormClickNextAction.AddToCart } : {}),
    }),
  );
}

function isOnlineFlow(
  checkoutResponse: CreateCheckoutResponse | CreateOrderResponse,
): checkoutResponse is CreateCheckoutResponse {
  return (checkoutResponse as CreateCheckoutResponse)?.checkout !== undefined;
}

async function updateCartWithMemberDetails({
  addToCurrentCartResponse,
  contactDetails,
  formApi,
  country,
  experiments,
}: {
  addToCurrentCartResponse?: AddToCartResponse;
  contactDetails: ContactDetails;
  formApi: FormApi;
  country: string;
  experiments: Experiments;
}) {
  const cartLineItems = addToCurrentCartResponse?.cart?.lineItems || [];
  const bookingsLineItems = mapCartToBookingsLineItems(cartLineItems);
  const isFirstBookingItemOnCart = bookingsLineItems.length === 1;
  if (isFirstBookingItemOnCart) {
    const isSendAddressToEcomCheckoutAndCartEnabled = experiments.enabled(
      ExperimentsConsts.SendAddressToEcomCheckoutAndCart,
    );
    const cartInfo: Cart = {
      id: addToCurrentCartResponse?.cart?.id,
      buyerInfo: {
        email: contactDetails.email ?? '',
      },
      contactInfo: {
        ...(isSendAddressToEcomCheckoutAndCartEnabled &&
        contactDetails.fullAddress &&
        country
          ? { address: { ...contactDetails.fullAddress, country } }
          : {}),
        contactDetails: formApi.mapContactDetails({ contactDetails }),
      },
    };
    const updateCartRequest: UpdateCartRequest = {
      cartInfo,
    };
    await formApi.updateCart(updateCartRequest);
  }
}

const getTimezone = (serviceData: ServiceData) => {
  const sharedService = mapToArray<SlotService>(serviceData.slotServices)[0];
  return sharedService.nestedSlot?.timezone;
};

const getFormSchema = (serviceData: ServiceData) => {
  return serviceData.form;
};
